/* Base Calendar Layout */
.rbc-calendar {
  box-sizing: border-box;
  height: 100% !important;
  min-height: 700px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  z-index: 1;
}

.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
  box-sizing: inherit;
}

/* Month View Styles */
.rbc-month-view {
  flex: 1;
  min-height: 0;
  height: 100% !important;
  border: 1px solid #DDD;
  z-index: 2;
}

/* 增加日历单元格的最小高度 */
.rbc-month-row {
  min-height: 240px !important;
}

.rbc-row-content {
  min-height: 230px !important;
  position: relative;
  user-select: none;
  z-index: 4;
}

/* Event Styles */
.rbc-event {
  background-color: #3174ad;
  border-radius: 4px;
  color: #fff;
  padding: 4px 6px;
  font-size: 0.85em;
  margin-bottom: 2px;
  cursor: pointer;
  z-index: 4;
  transition: all 0.2s ease;
}

.rbc-event:hover {
  filter: brightness(1.1);
}

/* Cell Highlighting */
.rbc-day-bg.highlight {
  background-color: #c6f6d5;
}

/* Today's Cell Styling */
.rbc-today {
  background-color: #ebf8ff;
}

/* Header Row Styling */
.rbc-header {
  padding: 8px 3px;
  font-weight: bold;
  border-bottom: 1px solid #DDD;
}

/* Selected Cell Styling */
.rbc-selected-cell {
  background-color: rgba(0, 120, 212, 0.1);
}

/* Off-range Dates */
.rbc-off-range-bg {
  background: #f5f5f5;
}

/* Month Row */
.rbc-month-row {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 0 0;
  overflow: hidden;
  height: 100%;
}

/* Time Grid */
.rbc-time-view {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  border: 1px solid #DDD;
  min-height: 0;
}

/* Time Grid Header */
.rbc-time-header {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
}

/* Time Grid Content */
.rbc-time-content {
  display: flex;
  flex: 1 0 0;
  align-items: flex-start;
  width: 100%;
  border-top: 2px solid #DDD;
  overflow-y: auto;
  position: relative;
}

/* Label Column */
.rbc-label {
  padding: 0 5px;
}

/* Time Indicator */
.rbc-current-time-indicator {
  position: absolute;
  z-index: 3;
  height: 1px;
  background-color: #74ad31;
  pointer-events: none;
}

/* Event Content */
.rbc-event-content {
  font-size: 0.85em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Event overflow handling */
.rbc-row-segment {
  padding: 2px 4px;
}

.rbc-show-more {
  background-color: transparent;
  color: #3174ad;
  padding: 2px 4px;
  font-size: 0.85em;
  cursor: pointer;
  text-decoration: underline;
}

/* Improve dragging visual feedback */
.rbc-addons-dnd .rbc-row-content {
  position: relative;
}

.rbc-addons-dnd .rbc-day-slot {
  position: relative;
}

.rbc-day-bg.rbc-drag-over {
  background-color: rgba(198, 246, 213, 0.5);
}